import React, { FC, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import useCMS from '@telescope/react-hooks/useCMS';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { addClickListener, initializeGoogleAnalytics, isInitialized, removeClickListener } from 'util/google-helpers';

import Article from 'components/article';
import Closed from 'components/closed';
import Footer from 'components/footer';
import Geo from 'components/geo';
import Grid from 'components/grid';
import Home from 'components/home';
import Header from 'components/header';
import HeroCarousel from 'components/hero-carousel';
import Loading from 'components/loading';

import { ICmsData } from 'models/api';
import { DEFAULT_WID, DEFAULT_POLL_RATE, AGE_GATE } from 'util/constants';
import { checkIfInRegion, fetchGeo, getQSPs, isTrue } from 'util/helpers';
import AgeGate from 'components/agegate';
import { IGenericObject } from 'models/base';
import { readCookie } from 'util/helpers';


interface CmsSettings {
  inRegion: boolean | null,
  pollRate: number
}

interface AppProps {
  locale: undefined | string;
  updateLocale: any;
  widgetId: undefined | string;
  routes: IGenericObject
}

const App: FC<AppProps> = ({ locale, updateLocale, widgetId, routes }) => {
  const { sid, wid = DEFAULT_WID } = getQSPs();
  const [allowUser, setAllowUser] = useState<boolean>(false);
  const [isReady, setIsReady] = useState(false);
  const [isArticle, setIsArticle] = useState(false);
  const [cmsSettings, setCmsSettings] = useState<CmsSettings>({ inRegion: null, pollRate: DEFAULT_POLL_RATE });
  const [cmsData] = useCMS<ICmsData>(`${widgetId}`, { sid, pollingFrequency: cmsSettings.pollRate, env: 'uk' });

  const {location} = useHistory();
  const locationState = useLocation();

  useEffect(() => {
    const locationArray = locationState.pathname && locationState.pathname.indexOf('news') > -1 && locationState.pathname.split('/');
    if ( locationArray && locationArray[2] === 'news' && !!locationArray[3]) {
      // Entering article
      setIsArticle(true);
      addClickListener();
    } else if ( isArticle ) {
      // Leaving article
      setIsArticle(false);
      removeClickListener();
    }
  }, [location]);

  const checkGeo = async () => {
    const geoData = await fetchGeo();
    if (geoData && geoData.geoheaders && cmsData.settings.countries.length) {
      const inRegion = checkIfInRegion(geoData.geoheaders.country, cmsData.settings.countries);
      setCmsSettings({ ...cmsSettings, inRegion });
    }
  };

  useEffect(() => {
    const agCookie = readCookie(AGE_GATE.cookieName);
    if (agCookie !== '') {
      setAllowUser(true);
    }
  });

  useEffect(() => {
    if (cmsData) {
      let pollRate = parseInt(cmsData.settings.poll_rate, 10) * 1000;

      if (!isInitialized()) {
        initializeGoogleAnalytics(cmsData.settings.google_analytics);
      }

      checkGeo();

      setCmsSettings({ ...cmsSettings, pollRate });
    }
    if (!isReady && cmsData) setIsReady(true);

  }, [cmsData, isReady]);

  const styles = styleSheet({});

  if (!isReady || cmsSettings.inRegion === null) {
    return <Loading />;
  }

  const headerData = { ...cmsData.text.header, inRegion: cmsSettings.inRegion };
  return (
    <div className={css(styles.app)}>
      <div className={css(styles.wrapper)}>
        <Header data={headerData}
                updateLocale={updateLocale}
                currentLocale={locale}
                routes={routes}
                hideNavigation={!allowUser}/>
        <Switch>

          {!cmsSettings.inRegion && location.pathname !== routes.GEO &&
            <Redirect exact to={routes.GEO} />}

          {cmsSettings.inRegion && (!isTrue(cmsData.settings.window_status) && location.pathname !== routes.CLOSED) &&
            <Redirect exact to={routes.CLOSED} />}

          {cmsSettings.inRegion && !allowUser && location.pathname !== routes.AGE_GATE && <Redirect exact to={routes.AGE_GATE} />}

          <Route exact path={routes.HOME}>
            <HeroCarousel routes={routes} data={cmsData.text.home.carousel}></HeroCarousel>
            <Home data={cmsData.text.home} />
          </Route>

          <Route exact path={routes.NEWS}>
            <Grid routes={routes} data={cmsData.text.grid} list={cmsData.data} />
          </Route>

          <Route path={routes.NEWS + '/:id'}>
            <Article routes={routes} data={cmsData.text.article} list={cmsData.data} />
          </Route>

          <Route exact path={routes.CLOSED}>
            {isTrue(cmsData.settings.window_status) && <Redirect exact to={routes.HOME} />}
            <Closed data={cmsData.text.closed} />
          </Route>

          <Route exact path={routes.GEO}>
            {cmsSettings.inRegion && <Redirect exact to={routes.CLOSED} />}
            <Geo data={cmsData.text.geo} />
          </Route>

          <Route exact path={routes.AGE_GATE}>
            {allowUser && <Redirect exact to={routes.HOME} />}
            <AgeGate onSuccess={setAllowUser} data={cmsData.text.agegate}/>
          </Route>

          <Route path={"*"}>
            {<Redirect exact to={routes.HOME} />}
          </Route>

        </Switch>
      </div>
      <Footer data={cmsData.text.footer} />
    </div>
  );
};

export default App;
